.block-image_text {
    position: relative;

    /*padding-left: var(--block-space-horizontal);*/
    /*padding-right: var(--block-space-horizontal);*/
}

.block-image_text .content {
    display: grid;
    gap: 2rem 8rem;
    max-width: var(--width-main);
    margin: 0 auto;
    padding: var(--block-space-vertical) var(--block-space-horizontal);
}

.block-image_text .image-container {
    padding-top: 0.75rem;
}

.block-image_text.image-right .image-container {
    padding-top: 0;
}

.block-image_text .image {
    margin: 0 calc(-1 * var(--block-space-horizontal)) 3rem;
    position: relative
}

.block-image_text .image::before {
    background-color: var(--color-yellow);
    content: '';
    height: calc(100% + 3rem);
    left: 0;
    position: absolute;
    top: -1rem;
    width: 90%;
}

.block-image_text.image-right .image::before {
    left: 10%;
    top: -2rem;
}

.block-image_text .image picture {
    position: relative;
    z-index: 2;
}

.block-image_text .logo {
    margin: 0 0 1rem;
}

.block-image_text .text p {
    margin: 0 0 3rem;
}

.block-image_text h4 {
    color: var(--color-yellow);
}

.block-image_text h2 {
    margin: 0 0 2rem;
}

.block-image_text.spaceTopAndBottom .content {
    padding-top: 0.75rem;
}

@media (min-width: 1080px) {
    .block-image_text .content {
        grid-template-columns: repeat(2, 1fr);
    }

    .block-image_text .content {
        display: grid;
        gap: 2rem 8rem;
        max-width: var(--width-main);
        margin: 0 auto;
        padding-top: var(--default-spacing);
        padding-bottom: var(--block-space-vertical);
        padding-left:  var(--block-space-horizontal);
        padding-right:  var(--block-space-horizontal);
    }

    .block-image_text.spaceTopAndBottom .content {
        padding-top: 0;
    }

    .block-image_text .content::before {
        background-color: var(--color-yellow);
        content: '';
        height: 30rem;
        left: 0;
        position: absolute;
        top: var(--block-space-vertical);
        width: 50%;
    }

    .block-image_text .image::before {
        display: none;
    }

    .block-image_text.image-right .content::before {
        left: calc(50% - 2rem);
        top: 4rem;
        width: calc(50% + 2rem);
    }

    .block-image_text.image-right .content {
        grid-template-areas: 'text image';
    }

    .block-image_text.image-right .image-container {
        grid-area: image;
    }

    .block-image_text .image {
        margin: 0 0 0 calc(-1 * var(--block-space-horizontal));
        position: relative;
    }

    .block-image_text .image picture,
    .block-image_text .image img {
        height: 30rem;
        object-fit: cover;
        width: 100%;
    }

    .block-image_text.image-right .image {
        margin: 0 calc(-1 * var(--block-space-horizontal)) 0 0;
    }

    .block-image_text.image-right .text {
        grid-area: text;
    }

    .block-image_text.image-right {
        padding-top: 4rem;
    }
}

